@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


 
/* width */
::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #070c3d;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #2c3ec7;
    border-radius: 20px;
    border: 1px solid #070c3d;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #475cfc;
}


@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

  .react-tel-input .form-control {
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 100% !important;
    outline: none;
    padding: 12px 14px 12px 58px !important;
    transition: box-shadow ease .25s,border-color ease .25s;
}